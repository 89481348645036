$primaryColor: #376e6f;
$secondColor: #1c3334;
$primaryColorACS: #21548A;
$secondColorACS: #19A1B6;
$thirdColorACS: '#277BC0';
$forthColor: #dbd7d7;
$lineColor: #e8e7e7;
$lightBlue: '#add8e6';
$darkBlue: '#002B5B';
//rosu opac
$redOpac: 'rgba(255,0,0,0.7)';
$red: 'rgb(255,0,0)';
//verde opac
$green: 'rgba(55, 110, 111, 0.7)';
$greenOpac: 'rgba(55, 110, 111, 0.2)';
$lightGreen: "#456D6E";
$white: white;
$black: black;
$font: 'Montserrat';
