@import '../../main.scss';
@import '../../theme.scss';


.wrapper {
  @include layout(row, center, center);
  font-family: $font;
  .container {
    @include layout(row, center, center);
    margin-top: 5%;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    width: auto;
    padding: 3%;
    .content {
      @include layout(column, center, center);
      margin: 18% 0;
      a {
        color:$primaryColor;
        font-weight: bold;
        font-size: 1rem;
      }
    }

    .contentACS {
      @include layout(column, center, center);
      margin: 18% 0;
      a {
        color:$primaryColorACS;
        font-weight: bold;
        font-size: 1rem;
      }
    }

    label {
      @include layout(row, flex-start, flex-start);
      color: $black;
      padding: 5% 0 0 0;
    }
  }
}
@media only screen and (max-width: 800px) {

  .wrapper {
    @include layout(row, center, center);
    font-family: $font;
    .container {
      @include layout(row, center, center);
      margin-top: 10%;
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;;
      width: 70%;
      padding: 2%;
      .content {
        @include layout(column, center, center);
        margin: 18% 0;
      }
  
      label {
        @include layout(row, flex-start, flex-start);
        color: $black;
        padding: 5% 0 0 0;
      }
    }
  }
}


@media only screen and (max-width: 500px) {

  .wrapper {
    @include layout(row, center, center);
    font-family: $font;
    .container {
      @include layout(row, center, center);
      margin-top: 20%;
      box-shadow: none;
      width: 70%;
      padding: 1%;
      .content {
        @include layout(column, center, center);
        margin: 18% 0;
      }
  
      label {
        @include layout(row, flex-start, flex-start);
        color: $black;
        padding: 5% 0 0 0;
      }
    }
  }
}

:global {
  .ant-tabs-tab:hover {
    color: $black !important;
    font-family: $font;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $black !important;
    font-family: $font;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none !important;
    font-family: $font;
  }
  .ant-tabs-ink-bar {
    background: #d9d9d9 !important;
    font-family: $font;
  }
  .ant-tabs-tab {
    margin: 0 16px !important;
    padding-top: 22px !important;
    font-family: $font;
  }
  .ant-row {
      width:100% !important;
      font-family: $font;
  }
}